import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    newItemBox:theme.newsItemBox,
    newsItemInnerBox:theme.newsItemInnerBox
}));

export default () => {
    const classes = useStyles();

    return (
        <div className={classes.newsItemBox} style={{textAlign: "center"}}>
            <div className={classes.newsItemBox + " " + classes.newsItemInnerBox}>
                <img src="/inc/imgs/exalted_crypt.jpg" alt="Exalted Crypt" />
                <img src="/inc/imgs/exalted_undercrypt.jpg" alt="Exalted Under Crypt" />
            </div>
        </div>
    )
}