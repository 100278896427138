import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Blog from '../blog/Blog';
import AOResources from './AOResources';

const useStyles = makeStyles(theme => ({
    content: theme.content,
    newsItemBox: theme.newsItemBox,
    newsItemInnerBox: theme.newsItemInnerBox,
    tablink: theme.tablink,
    tabcontent: theme.tabcontent,
    selectedTab: theme.selectedTab
}));

let tabs = [
    {selected: true, display: "block"},
    {selected: false, display: "none"},
    {selected: false, display: "none"},
    {selected: false, display: "none"}
]

export default () => {
    const classes = useStyles();
    const [displayTabs, setDisplayTabs] = useState(tabs);

    const openPage = (tabID) => {
        tabs.map((tab, idx) => {
            if(tabID === idx) {
                tab.selected = true;
                tab.display = "block";
            } else {
                tab.selected = false;
                tab.display = "none";
            }
            return tab;
        })

        setDisplayTabs(JSON.parse(JSON.stringify(tabs)));
    }

    return (
        <div className={classes.content}>
            <button className={classes.tablink + " " + (displayTabs[0].selected?classes.selectedTab:"")} onClick={() => {openPage(0)}} id="defaultOpen">News</button>
            <button className={classes.tablink + " " + (displayTabs[1].selected?classes.selectedTab:"")} onClick={() => {openPage(1)}}>AO Videos</button>
            <button className={classes.tablink + " " + (displayTabs[2].selected?classes.selectedTab:"")} onClick={() => {openPage(2)}}>ANZAC Alliance</button>
            <button className={classes.tablink + " " + (displayTabs[3].selected?classes.selectedTab:"")} onClick={() => {openPage(3)}}>AO Resources</button>

            <div id="News" className={classes.tabcontent} style={{display: displayTabs[0].display}}>
                <Blog channel="ao-blog" />
            </div>
            <div id="Videos" className={classes.tabcontent} style={{display: displayTabs[1].display}}>
                <Blog channel="ao-video-blog" />
            </div>
            <div id="ANZAC" className={classes.tabcontent} style={{display: displayTabs[2].display}}>
                <Blog channel="ao-anzac-alliance" />
            </div>
            <div id="Resources" className={classes.tabcontent} style={{display: displayTabs[3].display}}>
                <AOResources />
            </div>
        </div>
    )
}