import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Heading from '../common/Heading';
import { Avatar, Button, Grid, TextField } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import HRuleSmall from '../common/HRuleSmall';
import { Link, Redirect } from 'react-router-dom';

import Loading from '../common/Loading';
import { AuthenticationContext } from '../../context/AuthenticationContext';

const useStyles = makeStyles(theme => ({
    content: theme.panelContent,
    signinPanel: theme.signinPanel,
    regoPanel: theme.regoPanel,
    signinBtn: theme.signinBtn,
    signinText: theme.signinText,
    signinError: theme.signinError
}));

export default () => {
    const classes = useStyles();
    const { signUp, signOut } = useContext(AuthenticationContext);
    const [regoError, setRegoError] = useState("");
    const [signingIn, setSigningIn] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const handleEmail = (e) => {
        setEmail(e.target.value);
    }

    const handlePassword = (e) => {
        setPassword(e.target.value);
    }

    const handlePassword2 = (e) => {
        setPassword2(e.target.value);
    }    

    const handleSubmit = (e) => {
        e.preventDefault();
        setSigningIn(true);
        setRegoError('');
        signUp({email, password})
        .then(data => {
            setEmail("");
            setPassword("")
            signOut();
            setRegistered(true);            
        })
        .catch(err => {
            setRegoError(err.message);
            setSigningIn(false);
        })
    }

    return (
        <>
            {registered && <Redirect to="/signin"/>}
            <div className={classes.content + " " + classes.signinPanel + " " + classes.regoPanel} style={{boxShadow:"4px 4px grey"}}>
                <Avatar style={{margin:"0 auto 10px"}}>
                    <LockOutlinedIcon />
                </Avatar>
                <Heading heading="Its Newbie Registration" color="black" />
                <HRuleSmall />
                {!signingIn?
                    <form onSubmit={handleSubmit} style={{marginBottom:"40px"}}>
                        <Grid container style={{marginTop:"20px"}}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8}>
                                <TextField id="email" value={email} onChange={handleEmail} label="Email (Used to login with)" style={{ margin: 8 }} placeholder="Email Address" helperText="" fullWidth margin="normal" InputLabelProps={{shrink: true,}} variant="outlined"/>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8} style={{marginTop:"20px"}}>
                                <TextField id="password" value={password} type="password" onChange={handlePassword} label="Password" style={{ margin: 8 }} placeholder="Password" helperText="" fullWidth margin="normal" InputLabelProps={{shrink: true,}} variant="outlined"/>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8} style={{marginTop:"20px"}}>
                                <TextField id="password2" value={password2} type="password" onChange={handlePassword2} label="Retype Password" style={{ margin: 8 }} placeholder="Password" helperText="" fullWidth margin="normal" InputLabelProps={{shrink: true,}} variant="outlined"/>
                            </Grid>
                            <Grid item xs={2}></Grid>                            
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8} style={{marginTop:"20px",textAlign:"center"}}>
                                <Button className={classes.signinBtn} type="submit" fullWidth variant="contained" color="primary">Register Details</Button>
                            </Grid>
                            <Grid item xs={2}></Grid>                                            
                        </Grid>
                    </form>
                :
                    <>
                        <div style={{marginTop:"75px"}}></div>
                        <Loading message="Registering your credentials..."/>
                        <div style={{marginBottom:"80px"}}></div>
                    </>
                }
                <HRuleSmall/>
                <Grid container style={{marginTop:"20px"}}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        {regoError?
                            <p className={classes.signinText + " " + classes.signinError}>{regoError}</p>
                        :
                            <p className={classes.signinText}>Already have an Account, <Link to="signin">Signin Here</Link></p>
                        }
                    </Grid>
                </Grid>
            </div>
        </>
    )
}