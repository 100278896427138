export const months = ["January","Febuary","March","April","May","June","July","August","September","October","November","December"];
export const days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

export const usefulLinks = [
    {
        name: "Albion Online 2D",
        url: "https://www.albiononline2d.com/"
    },
    {
        name: "Albion Online Guides",
        url: "https://albiononline.com/en/guides/?p=0&f=&s="
    },
    {
        name: "Albion Online Build Ideas",
        url: "https://www.albiononline2d.com/en/meta"
    },
    {
        name: "Albion Online Server Status",
        url: "https://www.albionstatus.com/"
    },
    {
        name: "Albion Online Offical Forums",
        url: "https://forum.albiononline.com"
    }    
];

export const newPost = {
    title: "",
    content: ""
}

export const newAccounts = {
    balance: 0,
    inventory: [],
    farms: [],
    sales: []
}