import React, {userState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { DataAccessContext } from '../../../context/DataAccessContext';
import { Button, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    content: theme.subHome,
    grid: theme.grid,
    contentBGOpacity: theme.contentBGOpacity,
    btns: theme.btns
}));

export default () => {
    const classes = useStyles();
    const {read, write, add, remove} = useContext(DataAccessContext);

    return (
        <div className={classes.content + " " + classes.contentBGOpacity}>
            <h1>All Transaction will show here.</h1>
        </div>
        
    );
}