import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';

import { AuthenticationContext } from '../../context/AuthenticationContext';
import MenuBtns from './MenuBtns';
import AdminBtns from './AdminBtns';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
  },
  spacer2: theme.spacer,
  affiliate: theme.affiliate,
  btns: theme.btns
}));

export default function Navbar() {
  const classes = useStyles();
  const {isAuthenticated, signOut} = useContext(AuthenticationContext);
  const showLogin = false;

  return (
	<div className={classes.root}>
		<AppBar position="static">
			<Toolbar>
				<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
				<a href="/"><img src="/inc/imgs/itsnewbie_logo.png" alt="Its Newbie" width="220" height="52" /></a>
				</IconButton>
				{isAuthenticated && <MenuBtns />}
				<div className={classes.spacer2}></div>
				{isAuthenticated && <AdminBtns />}
				<div className={classes.spacer}></div>
        {showLogin && (
            isAuthenticated?
              <Button color="inherit" className={classes.btns} onClick={() => {signOut()}} component={Link} to="/">Sign Out</Button>
            :
              <Button color="inherit" className={classes.btns} component={Link} to="/signin">Login</Button>
          )
        }

        <div style={{display:"inline-block",width:"50px"}}></div>
        <Button color="inherit" className={classes.btns}><a className={classes.affiliate} href="https://albiononline.com/?ref=62BLNYTBT5" target="_new">Join Albion Online</a></Button>
			</Toolbar>
		</AppBar>
	</div>
  );
}
