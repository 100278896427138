import React, { useState, useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Switch } from '@material-ui/core';

import { AuthenticationContext } from '../../context/AuthenticationContext';
import Loading from '../common/Loading';

const useStyles = makeStyles(theme => ({
    newsItemBox: theme.newsItemBox,
    newsItemInnerBox: theme.newsItemInnerBox,
    newsTitle: theme.newsTitle,
    newsSubtitle: theme.newsSubtitle,
    newsBody: theme.newsBody,
    newsBodyContentAnchor: theme.newsBodyContentAnchor,
    newsHR: theme.newsHR,
    leftBtn: theme.leftBtn,
    deleteBtn: theme.deleteBtn
}));

export default ({post, channel, handleClose, submitPost, deletePost}) => {
    const {signedInUser} = useContext(AuthenticationContext);
    const classes = useStyles();
    const [title, setTitle] = useState(post.title);
    const [published, setPublished] = useState((post.published !== undefined)?post.published:(post.id?true:false));
    const [preview, setPreview] = useState(post.preview?post.preview:"");
    const [content, setContent] = useState(post.content);
    const [previewPost, setPreviewPost] = useState(false);
    let open = true;

    const handleTitle = (e) => {
        setTitle(e.target.value);
    }

    const handlePublished = (e) => {
        setPublished(published?false:true);
    }

    const handlePreview = (e) => {
        setPreview(e.target.value);
    }

    const handleDelete = () => {
        deletePost(post.id);
    }

    const handleSubmit = () => {
        let newsPost = {
            ...post,
            title: title,
            published: published,
            preview: preview,
            channel: channel,
            posted: Date.now(),
            username: signedInUser.username,
            uid: signedInUser.uid,
            content: content
        }
        if(post.id !== undefined) {
            newsPost.posted = post.posted;
            newsPost.id = post.id;
        }

        submitPost(newsPost);
    }

    const handleEditorChange = (content, editor) => {
        setContent(content);
    }

    const handlePreviewPost = () => {
        if(previewPost) {
            setPreviewPost(false);
            return;
        }
        setPreviewPost(true);
    }

    return (
        <Dialog fullWidth={true} maxWidth="xl" open={open} onClose={handleClose} disableEnforceFocus disableAutoFocus={true} aria-labelledby="postEditor">
            <DialogTitle id="postEditor">Post Editor</DialogTitle>
            <DialogContent>
                {previewPost?
                    <div className={classes.newsItemBox}>
                        <div className={classes.newsItemBox + " " + classes.newsItemInnerBox}>
                            <div className={classes.newsTitle}>
                                <h2>{title}</h2>
                            </div>
                            <hr className={classes.newsHR} />
                            <div className={classes.newsBody} dangerouslySetInnerHTML={{__html: preview}}></div>
                            <div className={classes.newsBody} dangerouslySetInnerHTML={{__html: content}}></div>
                        </div>
                    </div>
                :
                    <form onSubmit={e => e.preventDefault()}>
                        {signedInUser?
                            <Grid container>
                                <Grid item xs={2}></Grid>
                                    <Grid item xs={5}>
                                        <TextField id="title" value={title} onChange={handleTitle} label="Title" style={{ margin: 8 }} placeholder="Please enter title of your post here" helperText="" fullWidth margin="normal" InputLabelProps={{shrink: true,}} variant="outlined"/>
                                    </Grid>
                                    <Grid item xs={3} style={{textAlign:"right"}}>
                                        Draft<Switch checked={published} onChange={handlePublished} />Published
                                    </Grid>
                                <Grid item xs={2} ></Grid>
                                <Grid item xs={2} ></Grid>
                                    <Grid item xs={8}>                                    
                                        <TextField id="username" value={signedInUser.username} label="Posted By" disabled={true} style={{ margin: 8 }} placeholder="Posted By" helperText="" fullWidth margin="normal" InputLabelProps={{shrink: true,}} variant="outlined"/>
                                    </Grid>
                                <Grid item xs={2} ></Grid>
                                <Grid item xs={2}></Grid>
                                    <Grid item xs={8}>
                                        <TextField id="preview" value={preview} onChange={handlePreview} label="Preview" style={{ margin: 8 }} placeholder="Please enter the post preview text of your post here" helperText="" fullWidth margin="normal" InputLabelProps={{shrink: true,}} variant="outlined"/>
                                    </Grid>
                                <Grid item xs={2} ></Grid>                            
                                <Grid item xs={2} ></Grid>
                                    <Grid item xs={8}>
                                        <Editor
                                            apiKey="tuu8stpatnoebquzzd0gknp35ouynf8fwyrdo410owldm7t4"
                                            initialValue={content}
                                            init={{
                                            height: 500,
                                            menubar: true,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code help'
                                            }}
                                            onEditorChange={handleEditorChange}
                                        />
                                    </Grid>
                                <Grid item xs={2} ></Grid>                        
                            </Grid>
                        :
                            <Loading message="User details are not loaded. Posting is unavailable." />
                        }
                    </form>
                }
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={2}>
                        <Button className={classes.leftBtn} onClick={handlePreviewPost} color="secondary">{previewPost?"Edit Post":"Preview Post"}</Button>
                    </Grid>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={3} style={{display:"flex",justifyContent:"flex-end"}}>
                        {post.id && signedInUser?
                            <Button className={classes.deleteBtn} onClick={handleDelete}>Delete Post</Button>
                        :
                            <></>
                        }
                        {signedInUser?
                            <Button onClick={handleSubmit} color="secondary">Submit Post</Button>
                        :
                            <></>
                        }
                        <Button onClick={handleClose} color="secondary">Close</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}