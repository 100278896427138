import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import Apple from '@material-ui/icons/Apple';
import { Link } from 'react-router-dom';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu elevation={0} getContentAnchorEl={null} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} transformOrigin={{vertical: 'top', horizontal: 'center'}} {...props} />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
	setAnchorEl(event.currentTarget);
	console.log('ANCHOR = ',anchorEl);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
		<Button aria-controls="customized-menu" aria-haspopup="true" variant="contained" color="primary" onClick={handleClick}>
			Tools
		</Button>
		<StyledMenu id="tools-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} onClick={handleClose}>
			<StyledMenuItem component={Link} to="/farming">
				<ListItemIcon>
				<Apple fontSize="small" />
				</ListItemIcon>
				<ListItemText primary="Farming"/>
			</StyledMenuItem>
			<StyledMenuItem>
				<ListItemIcon>
				<DraftsIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText primary="Drafts" />
			</StyledMenuItem>
			<StyledMenuItem>
				<ListItemIcon>
				<InboxIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText primary="Inbox" />
			</StyledMenuItem>
		</StyledMenu>
    </div>
  );
}
