import React, {createContext} from 'react';

import {firestoreAPI, localStorageAPI} from '../utilities/dataAPI';

export const DataAccessContext = createContext();

export default (props) => {
    const api = new firestoreAPI(props.fbStore);
    //const api = new localStorageAPI();

    const read = (collection, orderBy = null, where = null, startAfter = null, pageSize = null) => {
        return api.read(collection, orderBy, where, startAfter, pageSize);
    }

    const write = (collection, id, data) => {
        return api.write(collection, id, data);
    }

    const add = (collection, data) => {
        return api.add(collection, data);
    }
    
    const remove = (collection, id) => {
       return api.remove(collection, id);
    }

    return (
        <DataAccessContext.Provider value={{read, write, add, remove}}>
            {props.children}
        </DataAccessContext.Provider>
    );
}