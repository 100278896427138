import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Heading from '../common/Heading';
import { Avatar, Button, Grid, TextField } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import HRuleSmall from '../common/HRuleSmall';
import { Redirect } from 'react-router-dom';

import Loading from '../common/Loading';
import { AuthenticationContext } from '../../context/AuthenticationContext';

const useStyles = makeStyles(theme => ({
    content: theme.panelContent,
    signinPanel: theme.signinPanel,
    profilePanel: theme.profilePanel,
    signinBtn: theme.signinBtn,
    signinText: theme.signinText,
    signinError: theme.signinError
}));

export default () => {
    const classes = useStyles();
    const {fbStore, signedInUser} = useContext(AuthenticationContext);
    const [regoError, setRegoError] = useState("");
    const [updating, setUpdating] = useState(false);
    const [registered, setRegistered] = useState(false);
    const [username, setUsername] = useState("");

    const updateProfile = () => {
        let query = fbStore.collection('users').where('username', '==', username);

        let usernameCheck = query.get().then(snapshot => {
            let results = snapshot.docs.map(doc => doc.data());
            
            return (results.length > 0? true:false);
        });

        usernameCheck.then(result => {
            if(result) {
                setRegoError('Sorry Username already taken');
                setUpdating(false);
            } else {
                fbStore.collection('users').doc(signedInUser.uid).set({
                    ...signedInUser,
                    username
                })
                .then(() => {
                    setRegistered(true);
                })
                .catch(err => {
                    setRegoError(err.message);
                });    
            }
        })
    }

    const handleUsername = (e) => {
        setUsername(e.target.value);
    }  

    const handleSubmit = (e) => {
        e.preventDefault();
        setUpdating(true);
        updateProfile(username);
    }

    return (
        <>
            {registered && <Redirect to="/"/>}
            <div className={classes.content + " " + classes.signinPanel + " " + classes.profilePanel} style={{boxShadow:"4px 4px grey"}}>
                <Avatar style={{margin:"0 auto 10px"}}>
                    <LockOutlinedIcon />
                </Avatar>
                <Heading heading="Welcome to Its Newbie dot com" color="black" />
                <HRuleSmall />
                {!updating?
                    <form onSubmit={handleSubmit} style={{marginBottom:"40px"}}>
                        <Grid container style={{marginTop:"20px"}}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8}>
                                <TextField id="username" value={username} onChange={handleUsername} label="Username" style={{ margin: 8 }} placeholder="Your Username must be unique" helperText="" fullWidth margin="normal" InputLabelProps={{shrink: true,}} variant="outlined"/>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8} style={{marginTop:"20px",textAlign:"center"}}>
                                <Button className={classes.signinBtn} type="submit" fullWidth variant="contained" color="primary">Submit Username</Button>
                            </Grid>
                            <Grid item xs={2}></Grid>                                            
                        </Grid>
                    </form>
                :
                    <>
                        <div style={{marginTop:"55px"}}></div>
                        <Loading message="Checking and saving your Username..."/>
                        <div style={{marginBottom:"50px"}}></div>
                    </>
                }
                <HRuleSmall/>
                <Grid container style={{marginTop:"20px"}}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        {regoError && <p className={classes.signinText + " " + classes.signinError}>{regoError}</p>}
                    </Grid>
                </Grid>
            </div>
        </>
    )
}