import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import DataAccessContextProvider from './context/DataAccessContext';
import { AuthenticationContext } from './context/AuthenticationContext';
import theme from './context/Theme';
import Navbar from './components/navbar/Navbar';
import Loading from './components/common/Loading';
import SignIn from './components/signin/SignIn';
import Register from './components/signin/Register';
import UserProfile from './components/signin/UserProfile';
import Home from './components/home/Home';
import MerlynsStatic from './components/home/MerlynsStatic';
import Farms from './components/tools/farming/Farms';

export default () => {
	const {isLoading, isAuthenticated, signedInUser, fbStore} = useContext(AuthenticationContext);
	const [noUserName, setNoUserName] = useState(false);

	useEffect(() => {
		if(!isLoading && isAuthenticated) {
			if(signedInUser.username === '') {
				setNoUserName(true);
			}
		}
	}, [noUserName, signedInUser, isAuthenticated, isLoading])

	return (
			isLoading?
				<Loading message="Initialising itsnewbie.com ..." />
			:
				<DataAccessContextProvider fbStore={fbStore}>
					<BrowserRouter>
						<CssBaseline />
						<ThemeProvider theme={theme}>
							{noUserName && <Redirect to="/userprofile"/>}
							<Navbar />
							<Switch>
								<Route exact path="/" component={Home} />
								<Route exact path="/signin" component={SignIn} />
								<Route exact path="/userprofile" component={UserProfile} />
								<Route exact path="/register" component={Register} />
								<Route path="/farming" component={Farms} />
								<Route exact path="/resources/merlyns-static-dungeon-map" component={MerlynsStatic}/>
							</Switch>
						</ThemeProvider>
					</BrowserRouter>
				</DataAccessContextProvider>
	);
}
