import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import AuthenticationContextProvider from './context/AuthenticationContext';

ReactDOM.render(
	<React.StrictMode>
		<AuthenticationContextProvider>
			<App />
		</AuthenticationContextProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

serviceWorker.unregister();
