export default (uid, firestore) => {
    const getUser = () => {
        let user = firestore.collection('users').where('uid', '==', uid);

        return user.get().then(snapshot => {
            let results = snapshot.docs.map(doc => doc.data());
            return results[0];
        })
    }

    if(uid) {
        return getUser().then(user => {
            return user;
        });
    }
}