import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
    divider: {
        marginTop: "-10px",
        height: "5px"
    }
}));

export default () => {
    const classes = useStyles();

    return (
        <Divider variant="middle" className={classes.divider} />
    )
}