import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { AuthenticationContext } from '../../context/AuthenticationContext';
import { DataAccessContext } from '../../context/DataAccessContext';
import Heading from '../common/Heading';
import Loading from '../common/Loading';
import ResourceEditor from './ResourceEditor';

const useStyles = makeStyles(theme => ({
    newsItemBox:theme.newsItemBox,
    newsItemInnerBox:theme.newsItemInnerBox,
    newsAnchor:theme.newsAnchor,
    linksAddBtn:theme.linksAddBtn,
    linksEditBtn:theme.linksEditBtn
}));

export default () => {
    const classes = useStyles();
    const {read, write, add, remove} = useContext(DataAccessContext);
    const {signedInUser} = useContext(AuthenticationContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const [urls, setUrls] = useState([]);
    const [resource, setResource] = useState("");
    const [showResourceEditor, setShowResourceEditor] = useState(false);

    useEffect(() => {
        if(!urls.length) {
            let links = read('links',{field:'posted', direction:'asc'});
            links.get().then(snapshot => {
                let results = snapshot.docs.map(doc => {
                    let dbDoc = doc.data();
                    dbDoc.id = doc.id;
                    return dbDoc;
                });
                setUrls(results);
                setIsLoaded(true);    
            });
        }
    }, [urls, read])

    const openDialog = () => {
        setShowResourceEditor(true);
    }

    const closeDialog = () => {
        setShowResourceEditor(false);
    }

    const editResource = (linkID) => {
        let re = urls.filter(link => link.id === linkID)[0];
        setResource(re);
        setShowResourceEditor(true);
    }
    
    const submitResource = (data) => {
        closeDialog();
        let oldResource = urls.filter(link => link.id === data.id);
        if(oldResource.length > 0) {
            write('links', data.id, data)
        } else {
            add('links', data);
        }
        setResource("");
    }

    const deleteResource = (linkID) => {
        closeDialog();
        remove('links', linkID);
    }

    return (
        <div className={classes.newsItemBox}>
            <div className={classes.newsItemBox + " " + classes.newsItemInnerBox}>
                {showResourceEditor && <ResourceEditor resource={resource} handleClose={closeDialog} submitResource={submitResource} deleteResource={deleteResource}/>}
                {isLoaded?
                    <Grid container>
                        <Grid item xs={6}>
                            <Heading heading="Official Albion Online Links" underline={true}/>
                            {signedInUser.uid && <Icon className={classes.linksAddBtn} onClick={openDialog}>add_circle</Icon>}
                        </Grid>
                        <Grid item xs={6}>
                            <Heading heading="Community Links" underline={true}/>
                            {signedInUser.uid && <Icon className={classes.linksAddBtn} onClick={openDialog}>add_circle</Icon>}
                        </Grid>
                        <Grid item xs={6}>
                            <ul>
                                {urls.map((url, idx) => {
                                    if(url.category === "official") {
                                        return (
                                            <li key={"OFF-"+idx}>
                                                <a className="newsAnchor" href={url.url} target="_link">{url.name}</a>
                                                {signedInUser.uid && <Icon key={"ICO-"+idx} className={classes.linksEditBtn} onClick={() => {editResource(url.id)}}>edit_circle</Icon>}
                                            </li>
                                        )
                                    }
                                    return null
                                })}
                            </ul>
                        </Grid>
                        <Grid item xs={6}>
                            <ul>
                                {urls.map((url, idx) => {
                                    if(url.category === "community") {
                                        return (
                                            <li key={"COM-"+idx}>
                                                <a className="newsAnchor" href={url.url} target="_link">{url.name}</a>
                                                {signedInUser.uid && <Icon key={"CICO-"+idx} className={classes.linksEditBtn} onClick={() => {editResource(url.id)}}>edit_circle</Icon>}
                                            </li>
                                        )
                                    }
                                    return null
                                })}
                            </ul>
                        </Grid>
                        <Grid item xs={6}>
                            <Heading heading="ANZAC Alliance Resources" underline={true}/>
                            {signedInUser.uid && <Icon className={classes.linksAddBtn} onClick={openDialog}>add_circle</Icon>}
                        </Grid>
                        <Grid item xs={6}>
                        <Heading heading="Other AO Resources" underline={true}/>
                            {signedInUser.uid && <Icon className={classes.linksAddBtn} onClick={openDialog}>add_circle</Icon>}
                        </Grid>
                        <Grid item xs={6}>
                            <ul>
                                {urls.map((url, idx) => {
                                    if(url.category === "anzac") {
                                        return (
                                            <li key={"ANZ-"+idx}>
                                                <a className="newsAnchor" href={url.url} target="_link">{url.name}</a>
                                                {signedInUser.uid && <Icon key={"AICO-"+idx} className={classes.linksEditBtn} onClick={() => {editResource(url.id)}}>edit_circle</Icon>}
                                            </li>
                                        )
                                    }
                                    return null
                                })}
                            </ul>
                        </Grid>
                        <Grid item xs={6}>
                            <ul>
                                <li key="ANZA">
                                    <Link className="newsAnchor" style={{color:"#cccfd1"}} to="/resources/merlyns-static-dungeon-map">Merlyns' Static Dungeon Map</Link>
                                </li>
                                {urls.map((url, idx) => {
                                    if(url.category === "other") {
                                        return (
                                            <li key={"ANZ-"+idx}>
                                                <a className="newsAnchor" href={url.url} target="_link">{url.name}</a>
                                                {signedInUser.uid && <Icon key={"AICO-"+idx} className={classes.linksEditBtn} onClick={() => {editResource(url.id)}}>edit_circle</Icon>}
                                            </li>
                                        )
                                    }
                                    return null
                                })}
                            </ul>
                        </Grid>
                    </Grid>
                :
                    <Loading message="Fetching Resources..."/>
                }
            </div>
        </div>
    )
}