import React, { useState, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Avatar, Button, Grid, TextField } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { AuthenticationContext } from '../../context/AuthenticationContext';
import Heading from '../common/Heading';
import HRuleSmall from '../common/HRuleSmall';
import Loading from '../common/Loading';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    content: theme.panelContent,
    signinPanel: theme.signinPanel,
    signinBtn: theme.signinBtn,
    signinText: theme.signinText,
    signinError: theme.signinError
}))

export default () => {
    const classes = useStyles();
    const {isAuthenticated, signIn} = useContext(AuthenticationContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [authError, setAuthError] = useState("");
    const [signingIn, setSigningIn] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSigningIn(true);
        signIn({email, password})
        .then(user => {
        })
        .catch(err => {
            setSigningIn(false);
            setAuthError("Invalid Credentials");
        });
    }

    return (
        <>
            {isAuthenticated && <Redirect to="/"/>}
            <div className={classes.content + " " + classes.signinPanel} style={{boxShadow:"4px 4px grey"}}>
                <Avatar style={{margin:"0 auto 10px"}}>
                    <LockOutlinedIcon />
                </Avatar>
                <Heading heading="Its Newbie Signin" color="black" />
                <HRuleSmall />
                {!signingIn?
                    <form onSubmit={handleSubmit} style={{marginBottom:"40px"}}>
                        <Grid container style={{marginTop:"20px"}}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8}>
                                <TextField id="email" value={email} onChange={(e) => setEmail(e.target.value)} label="Email" style={{ margin: 8 }} placeholder="Email Address" helperText="" fullWidth margin="normal" InputLabelProps={{shrink: true,}} variant="outlined"/>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8} style={{marginTop:"20px"}}>
                                <TextField id="password" value={password} type="password" onChange={(e) => setPassword(e.target.value)} label="Password" style={{ margin: 8 }} placeholder="Password" helperText="" fullWidth margin="normal" InputLabelProps={{shrink: true,}} variant="outlined"/>
                            </Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8} style={{marginTop:"20px",textAlign:"center"}}>
                                <Button className={classes.signinBtn} type="submit" fullWidth variant="contained" color="primary">Sign In</Button>
                            </Grid>
                            <Grid item xs={2}></Grid>                                            
                        </Grid>
                    </form>
                :
                    <>
                        <div style={{marginTop:"75px"}}></div>
                        <Loading message="Checking your credentials..."/>
                        <div style={{marginBottom:"80px"}}></div>
                    </>
                }
                <HRuleSmall/>
                <Grid container style={{marginTop:"20px"}}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                        {authError?
                            <p className={classes.signinText + " " + classes.signinError}>{authError}</p>
                        :
                            <p className={classes.signinText}>Dont have an Account, <Link to="register">Register Here</Link></p>
                        }
                    </Grid>
                </Grid>
            </div>
        </>
    );
}