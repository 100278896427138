import React from 'react';

export default ({message}) => {
    return (
        <div style={{textAlign:'center'}}>
            <div>
                <h2>{message}</h2>
            </div>
        </div>
    );
}