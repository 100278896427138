import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import lightGreen from '@material-ui/core/colors/lightGreen';
import grey from '@material-ui/core/colors/grey';

export default createMuiTheme({
    palette: {
        primary: {
            main: "#546e7a"
        },
        secondary: {
            main: grey[800]
        },
        text: {
            secondary: '#D50000'
        },
        background: {
            paper: lightGreen[300],
            default: red
        }
    },
    grid: {
        padding: `${0.25 * 4}rem`,
        marginTop: "1%",
        backgroundColor: grey[800],
        borderRadius: "15px",
        color: "white"
    },
    contentBGOpacity: {
        backgroundColor: "rgba(96,125,139,0.8)"
    },
    subHome: {
        marginTop: "0.5%",
        backgroundColor: "rgba(96,125,139,0.8)",
        color: "white",
        padding: "25px 10px",
    },
    textField: {
        backgroundColor: "#9E9E9E"
    },
    seed: {
        backgroundColor: grey[500],
        width: "300px",
        display: "inline-block",
        margin: `${0.25 * 0.5}rem`,
        seedColor: {
            carrot: {
                color: "#FF5722"
            },
            bean: {
                color: "#388E3C"
            },
            wheat: {
                color: "#FFF176"
            },
            turnip: {
                color: "#9C27B0"
            },
            cabbage: {
                color: "#1B5E20"
            },
            potato: {
                color: "#FFF9C4"
            },
            corn: {
                color: "#FFC107"
            },
            pumpkin: {
                color: "#FF6F00"
            },
            arcane: {
                color: "#8D6E63"
            },
            comfrey: {
                color: "#AED581"
            },
            burdock: {
                color: "#FF1744"
            },
            dragon: {
                color: "#D50000"
            },
            foxglove: {
                color: "#FFAB91"
            },
            mullein: {
                color: "#FFAB91"
            },
            yarrow: {
                color: "#795548"
            }
        }
    },
    appbar: {
        position: "relative",
        width: "100%",
        height: "8%",
        backgroundColor: "#424242",
        logo: {
            position: "relative",
            display: "inline-block",
            left: "7.5%",
            top: "50%",
            transform: "translate(-50%, -50%)"
        }
    },
    spacer: {
        position: "relative",
        display: "inline-block",
        width: "120px"
    },
    dropdown: {
        position: "relative",
        display: "inline-block",
        transform: "translate(-50%, -50%)",
        verticalAlign: "-moz-middle-with-baseline",
    },
    dropbtn: {
        backgroundColor: "#607D8B",
        color: "white",
        padding: "12px",
        fontSize: "12px",
        border: "none",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#2980B9",
            color: "black",
            border: "white solid thin"
        },
        "&:focus": {
            backgroundColor: "#2980B9"
        }
    },
    dropdownContent: {
        display: "none",
        position: "absolute",
        backgroundColor: "#607D8B",
        minWidth: "240px",
        boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
        zIndex: "1",
        marginTop: "-2px"
    },   
    dcAnchors: {
        color: "black",
        padding: "12px 16px",
        textDecoration: "none",
        display: "block",
        "&:hover": {
            backgroundColor: "#ddd"
        }
    },
    show: {
        display: "block"
    },
    affiliate: {
        color:"white",
        textDecoration:"none"
    },
    btns: {
        boxSizing: "border-box",
        border: "1px solid",
        "&:hover": {
            border: "1px solid #546e7a"
        }
    },
    home: {
        position: "relative",
        display: "inline-block",
        right: "0.1%",
        verticalAlign: "-moz-middle-with-baseline",
        transform: "translate(-50%, -50%)",
    },
    content: {
        marginTop: "2%"
    },
    panelContent: {
        marginTop: "2%",
        backgroundColor: "#607D8B",
        color: "white",
        padding: "50px 20px",
        height: "100%",
    },
    signinPanel: {
        width: "35%",
        height: "500px",
        backgroundColor: lightGreen[300],
        margin: "2% auto 0",
        padding: "20px 10px",
        color: "black",
        borderRadius: "5px",
    },
    regoPanel: {
        height: "650px",
    },
    profilePanel: {
        height: "450px",
    },    
    signinBtn: {
        border: '1px thin black'
    },
    signinText: {
        textAlign: "center",
        fontSize: "large",
        "&a:hover": {
            color: "red"
        }
    },
    signinError: {
        color:"red"
    },
    addnewsbtn: {
        float: "right",
        marginTop: "-40px",
        backgroundColor: "#2980B9",
        color: "black",
        padding: "8px",
        fontSize: "12px",
        border: "none",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#607D8B",
            color: "white",
            border: "white solid thin"
        },
        "&:focus": {
            backgroundColor: "#607D8B",
            color: "white",
        }
    },
    editnewsbtn: {
        float: "right",
        marginTop: "-55px",
        backgroundColor: "#2980B9",
        color: "black",
        padding: "8px",
        fontSize: "12px",
        border: "none",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#607D8B",
            color: "white",
            border: "white solid thin"
        },
        "&:focus": {
            backgroundColor: "#607D8B",
            color: "white",
        }
    },
    loadMoreBtn: {
        textAlign: "center",
        cursor: "pointer",
        backgroundColor: "#36404a",
        padding: "1px",
        "&:hover": {
            backgroundColor: "#424242",
            color: "#00C853"
        }
    },
    newsItemBox: {
        backgroundColor: "#424242",
        padding: "10px",
        borderRadius: "5px",
        marginBottom: "5px",
        border:" solid #323b44"
    },
    newsDraftItemBox: {
        backgroundColor: "#EF5350",
        padding: "10px",
        borderRadius: "5px",
        marginBottom: "5px",
        border:" solid #323b44"
    },    
    newsItemInnerBox: {
        backgroundColor: "#36404a"
    },
    newsTitle: {
        textDecoration: "underline",
        color: "#00C853"
    },
    newsSubtitle: {
        color: "#cccfd1"
    },
    newsBody: {
        color: "#cccfd1"
    },
    newsBodyContentAnchor: {
        color: "#cccfd1",
        cursor: "pointer",
        "&:hover": {
            color: "#00C853",
            textDecoration: "none"
        }
    },
    newsHR: {
        width: "85%",
        height: "5px",
        backgroundColor: "#00C853",
        border: "none",
        textAlign: "left"
    },
    leftBtn: {
        float: "left"
    },
    rightBtn: {
        float: "right"
    },
    deleteBtn : {
        color: "red"
    },
    tablink: {
        backgroundColor: "#555",
        color: "white",
        float: "left",
        border: "none",
        outline: "none",
        cursor: "pointer",
        padding: "14px 16px",
        fontSize: "17px",
        width: "25%",
        "&:hover": {
            backgroundColor: "#777"
        }
    },
    tabcontent: {
        backgroundColor: "#607D8B",
        color: "white",
        display: "none",
        padding: "100px 20px",
        height: "100%",
    },
    selectedTab: {
        backgroundColor: "#607D8B"
    },
    linksAddBtn: {
        float: "right",
        marginTop: "-45px",
        color: "#607D8B",
        cursor: "pointer",
        "&:hover" : {
            color: "#00C853"
        }
    },
    linksEditBtn: {
        float: "right",
        marginTop: "-10px",
        color: "#607D8B",
        cursor: "pointer",
        "&:hover" : {
            color: "#00C853"
        }
    }    
});