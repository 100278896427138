import React from 'react';
import { Button, Grid, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { formatPostDate } from '../../utilities';

const useStyles = makeStyles(theme => ({
    newsItemBox: theme.newsItemBox,
    newsItemInnerBox: theme.newsItemInnerBox,
    newsTitle: theme.newsTitle,
    newsSubtitle: theme.newsSubtitle,
    newsBody: theme.newsBody,
    newsBodyContentAnchor: theme.newsBodyContentAnchor,
    newsHR: theme.newsHR,
}));

export default ({ post, handleClose }) => {
    const classes = useStyles();
    let open = true;

    const postedBy = (data) => {
        return <div className={classes.newsSubtitle}><b>Posted:</b> <i>{formatPostDate(post.posted)} by {post.username}</i></div>
    }

    return (
        <Dialog fullWidth={true} maxWidth="xl" open={open} onClose={handleClose} aria-labelledby="fullPost">
            <DialogContent>
                <div className={classes.newsItemBox}>
                    <div className={classes.newsItemBox + " " + classes.newsItemInnerBox}>
                        <div className={classes.newsTitle}>
                            <h2>{post.title}</h2>
                        </div>
                        {postedBy(post)}
                        <hr className={classes.newsHR} />
                        <div className={classes.newsBody} dangerouslySetInnerHTML={{__html: post.preview}}></div>
                        <div className={classes.newsBody} dangerouslySetInnerHTML={{__html: post.content}}></div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={2}>
                        <Button onClick={handleClose} color="secondary">Close</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>            
    )
}