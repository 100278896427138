//import axios from 'axios';
//import graphTestData from '../config/graphTestData';
import { months } from './data';

export const aoItemImageThumbnailBaseURL = 'https://albiononline2d.ams3.cdn.digitaloceanspaces.com/thumbnails/128/';
export const aoItemImageBaseURL = 'https://gameinfo.albiononline.com/api/gameinfo/items/';
export const aoPriceDataAPIBaseURL = 'https://www.albion-online-data.com/api/v1/stats/prices/';
export const aoCities = ["Arthurs Rest", "Bridgewatch", "Caerleon", "Fort Sterling", "Lymhurst", "Martlock", "Merlyns Rest", "Thetford"];
export const seeds = [{name: "Carrot Seeds",icon: "T1_FARM_CARROT_SEED",color:"carrot"},{name: "Bean Seeds",icon: "T2_FARM_BEAN_SEED",color:"bean"},{name: "Wheat Seeds", icon: "T3_FARM_WHEAT_SEED",color:"wheat"},{name: "Turnip Seeds", icon: "T4_FARM_TURNIP_SEED",color:"turnip"},{name: "Cabbage Seeds", icon: "T5_FARM_CABBAGE_SEED",color:"cabbage"},{name: "Potato Seeds", icon: "T6_FARM_POTATO_SEED",color:"potato"},{name: "Corn Seeds", icon: "T7_FARM_CORN_SEED",color:"corn"},{name: "Pumpkin Seeds", icon: "T8_FARM_PUMPKIN_SEED",color:"pumpkin"},{name: "Arcane Agaric Seeds", icon: "T2_FARM_AGARIC_SEED",color:"arcane"},{name: "Brightleaf Comfrey Seeds", icon: "T3_FARM_COMFREY_SEED",color:"comfy"},{name: "Crenellated Burdock Seeds", icon: "T4_FARM_BURDOCK_SEED",color:"burdock"},{name: "Dragon Teasel Seeds", icon: "T5_FARM_TEASEL_SEED",color:"dragon"},{name: "Elusive Foxglove Seeds", icon: "T6_FARM_FOXGLOVE_SEED",color:"foxglove"},{name: "Firetouched Mullein Seeds", icon: "T7_FARM_MULLEIN_SEED",color:"mullein"},{name: "Ghoul Yarrow Seeds", icon: "T8_FARM_YARROW_SEED",color:"yarrow"}];


const aoItemMetaDataBaseURL = 'https://raw.githubusercontent.com/broderickhyman/ao-bin-dumps/master/formatted/items.json';

export const newFarm = {
    name:"",
    uid:"",
    plots:0,
    seeds:[],
    harvest:[],
    journal:[],
    bank:[]
}

export function getItemIconThmubnailURL(itemID) {
    return aoItemImageThumbnailBaseURL + itemID
}

export function getItemIconURL(itemID) {
    return aoItemImageBaseURL + itemID
}

export function formatPostDate(date) {
    //March 9th, 2020 at 1:45

    const formatMinutes = (minutes) => {
        if(minutes < 10) {
            return "0" + minutes;
        }
        return minutes;
    }

    const formatDay = (day) => {
        let result = day;
        switch(day) {
            case 1:
                return result + "st";
            case 2:
                return result + "nd";
            case 3:
                return result + "rd";
            default:
                return result + "th";
        }
    }
     
    let d = new Date(date);
    return months[d.getMonth()] + " " + formatDay(d.getDate()) + ", " + d.getFullYear() + " at " + d.getHours() + ":" + formatMinutes(d.getMinutes()) + (d.getHours() > 11? " PM":" AM")
}

export default async function getItemMetaData() {
    const request = await fetch(aoItemMetaDataBaseURL);
    const items = await request.json();
    const aoItems = [];
    
    items.forEach(item => {
        if(item.hasOwnProperty('LocalizedNames')) {
            if(item.LocalizedNames !== null && item.LocalizedNames !== '') {
                aoItems.push(item);
            }
        }
    });

    return aoItems;
}

export async function getMarkPrices(itemID) {
    const request = await fetch(aoPriceDataAPIBaseURL + itemID);
    return await request.json();
}

export function getMarketItemPriceData() {
    let data = [
        {x: 0, y: 8},
        {x: 1, y: 5},
        {x: 2, y: 4},
        {x: 3, y: 9},
        {x: 4, y: 1},
        {x: 5, y: 7},
        {x: 6, y: 6},
        {x: 7, y: 3},
        {x: 8, y: 2},
        {x: 9, y: 0}
      ];


    return data;
}

export function menu() {
    document.getElementById("myDropdown").classList.toggle("show");
}