import {v4 as uuid } from 'uuid';

export class firestoreAPI {
    constructor(fbStore) {
        this.fbStore = fbStore;
    }

    read(collection, orderBy, where, startAfter, pageSize) {
        let fs = this.fbStore.collection(collection);

        if(orderBy !== null) fs = fs.orderBy(orderBy.field, orderBy.direction);
        if(where !== null) fs = fs.where(where.field, where.operator, where.value);
        if(startAfter !== null) fs = fs.startAfter(startAfter);
        if(pageSize !== null) fs = fs.limit(pageSize);

        return fs;
    }

    write(collection, id, data) {
        return this.fbStore.collection(collection).doc(id).set({...data});
    }

    add(collection, data) {
        return this.fbStore.collection(collection).add(data);
    }

    remove(collection, id) {
        return this.fbStore.collection(collection).doc(id).delete();
    }
}

export class localStorageAPI {
    constructor() {
        this.db = localStorage;
    }

    read(collection, orderBy, where, startAfter, pageSize) {
        let data = JSON.parse(this.db.getItem(collection));
        if(orderBy.direction === 'desc') {
            data.sort((a,b) => {return a[orderBy.field] + b[orderBy.field]})
        } else {
            data.sort((a,b) => {return a[orderBy.field] - b[orderBy.field]})
        }
        if(!data) data = [];
        data = data.filter(item => item[where.field] === where.value);
        let cnt = 0;
        let flag = false;
        data = data.filter((item) => {
            if(startAfter) {
                if(item[orderBy.field] === startAfter) {
                    flag = true;
                    return null;
                }
                if(flag) {
                    if(pageSize > 0) {
                        if(cnt < pageSize) {
                            cnt++;
                            return item;
                        }
                    } else {
                        return item;
                    }
                }
            } else {
                if(pageSize > 0) {
                    if(cnt < pageSize) {
                        cnt++;
                        flag = true;
                        return item;
                    }
                } else {
                    return item;
                }                
            }
            return null;
        });
        let result = {
            get: () => {
                return new Promise((resolve, reject) => {
                    let docs = data.map(item => {
                        return {
                            id: item.id,
                            data: () => {
                                return item;
                            }
                        }
                    });

                    resolve({docs});
                 });
            }
        }
        return result;
    }

    write(collection, id, data) {
        let col = JSON.parse(this.db.getItem(collection));

        col = col.map(item => {
            if(item.id === id) {
                item = data;
            }
            return item;
        });
        this.db.setItem(collection, JSON.stringify(col));
    }

    add(collection, data) {
        let col = JSON.parse(this.db.getItem(collection));
        col = col? col : [];
        data.id = uuid();
        col.push(data);
        this.db.setItem(collection, JSON.stringify(col));
    }

    remove(collection, id) {
        let col = JSON.parse(this.db.getItem(collection));
        col = col.filter(item => item.id !== id);
        this.db.setItem(collection, JSON.stringify(col));
    }
}