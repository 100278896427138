import React, { useContext, useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthenticationContext, fbConfig } from '../../../context/AuthenticationContext';
import { DataAccessContext } from '../../../context/DataAccessContext';
import DataAccessContextProvider from '../../../context/DataAccessContext';
import Home from './Home';
import Crops from './Crops';
import FarmTransactions from './FarmTransactions';
import Loading from '../../common/Loading';
import FarmSetup from './FarmSetup';

const newFarm = {
    uid: "",
    crops: [],
    seedStorage: [],
    harvestStorage: [],
    transactions: [],
    plots: 0,
    plotsPlanted: 0
}

export default () => {
    const {isAuthenticated, signedInUser, fbStore} = useContext(AuthenticationContext);
    const {read, write, add, remove} = useContext(DataAccessContext);
    const [hasFarm, setHasFarm] = useState(false);
    const [userFarm, setUserFarm] = useState([]);
    const [error, setError] = useState('');
    const [needSetup, setNeedSetup] = useState(true);

    useEffect(() => {
        const getFarmDetails = () => {
            let fd = read('farms', null, {field:"uid", operator:"==", value:signedInUser.uid});

            fd.get().then(snapshot => {
                let ufd = snapshot.docs.map(doc => {
                    let dbDoc = doc.data();
                    dbDoc.id = doc.id;
                    return dbDoc;
                });

                if(ufd.length) {
                    setHasFarm(true);
                    setUserFarm(ufd[0]);
                    if(ufd[0].plots > 0) {
                        setNeedSetup(false);
                    }
                } else {
                    ufd = newFarm;
                    ufd.uid = signedInUser.uid;
                    write("farms", signedInUser.uid, ufd).then(() => {
                        setUserFarm(ufd);
                        setHasFarm(true);
                    })
                    .catch(err => {
                        setError(err.message);
                    });

                }
            })
        }

        if(signedInUser) {
            getFarmDetails();
        }
    }, [read, write, signedInUser])
    
    const handleUpdate = (plots) => {
        userFarm.plots = plots;
        write('farms', userFarm.uid, userFarm).then(() => {
            setNeedSetup(false);
        })
    }
    return (
        !isAuthenticated?
            <Redirect to="/"/>
        :
            <>
                <Route path="/farming" component={Home} />
                {hasFarm?
                    needSetup?
                        <FarmSetup handleUpdate={handleUpdate} />
                    :
                        <DataAccessContextProvider fbStore={fbStore}>
                            <Route exact path="/farming" component={FarmTransactions} />
                            <Route exact path="/farming/crops" component={Crops} />
                        </DataAccessContextProvider>
                :
                    <>
                        <Loading message="Loading User farm details..."/>
                        {error && <Loading message={error}/>}
                    </>
                }
            </>
    );
}