import React, { useState, useRef, useEffect } from 'react';
import { Button, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    deleteBtn: theme.deleteBtn,
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default ({resource, handleClose, submitResource, deleteResource}) => {
    const classes = useStyles();
    const [name, setName] = useState(resource.name?resource.name:"");
    const [url, setUrl] = useState(resource.url?resource.url:"");
    const [category, setCategory] = useState(resource.category?resource.category:"official");
    let open = true;
    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        if(inputLabel.current !== null) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, []);

    const handleName = (e) => {
        setName(e.target.value);
    }

    const handleUrl = (e) => {
        setUrl(e.target.value);
    }

    const handleCategory = (e) => {
        setCategory(e.target.value);
    }

    const handleDelete = () => {
        deleteResource(resource.id);
    }

    const handleSubmit = () => {
        let newResource = {
            ...resource,
            name: name,
            url: url,
            category: category
        }

        if(!resource.id) {
            newResource.posted = Date.now();
        }

        submitResource(newResource);
    }

    return (
        <Dialog fullWidth={true} maxWidth="xl" open={open} onClose={handleClose} disableEnforceFocus disableAutoFocus={true} aria-labelledby="resourceEditor">
            <DialogTitle id="resourceEditor">Resource Editor</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <TextField id="name" value={name} onChange={handleName} label="Name" style={{ margin: 8 }} placeholder="Please enter the name of this link" helperText="" fullWidth margin="normal" InputLabelProps={{shrink: true,}} variant="outlined"/>
                        </Grid>
                    <Grid item xs={4} ></Grid>
                    <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <TextField id="url" value={url} onChange={handleUrl} label="Url" style={{ margin: 8 }} placeholder="Please enter the URL for this link" helperText="" fullWidth margin="normal" InputLabelProps={{shrink: true,}} variant="outlined"/>
                        </Grid>
                    <Grid item xs={4} ></Grid>
                    <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">Category</InputLabel>
                                <Select native value={category} onChange={handleCategory} labelWidth={labelWidth} inputProps={{name: 'Category',id: 'outlined-age-native-simple'}}>
                                    <option value="official">Official</option>
                                    <option value="community">Community</option>
                                    <option value="anzac">ANZAC</option>
                                </Select>
                            </FormControl>                            
                        </Grid>
                    <Grid item xs={4} ></Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={9}></Grid>
                    <Grid item xs={3} style={{display:"flex",justifyContent:"flex-end"}}>
                        {resource.id?
                            <Button className={classes.deleteBtn} onClick={handleDelete}>Delete Resource</Button>
                        :
                            <></>
                        }
                        <Button onClick={handleSubmit} color="secondary">Add Resource</Button>
                        <Button onClick={handleClose} color="secondary">Close</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}