import React, {useContext, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Icon, InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';

import { AuthenticationContext } from '../../../context/AuthenticationContext';
import Heading from '../../common/Heading';
import Loading from '../../common/Loading';

const useStyles = makeStyles(theme => ({
    content: theme.subHome,
    grid: theme.grid,
    contentBGOpacity: theme.contentBGOpacity,
    table: {
        minWidth: 650,
    }
}));

export default () => {
    const classes = useStyles();
    const {isAuthenticated, signedInUser} = useContext(AuthenticationContext);
    const [filter, setFilter] = useState('planted');

    const handleChange = (e) => {
        setFilter(e.target.value);
    }

    return (
        !isAuthenticated?
            <Loading message="Loading Farm Data..."/>
        :
            <div className={classes.content + " " + classes.contentBGOpacity}>
                <Grid className={classes.grid} container justify="center">
                    <Grid item xs={12}>
                        <Heading color="white" heading="Farm Manager" />
                    </Grid>
                    <Grid item xs={6}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="Farm Manager - Crops">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Seeds In Storage</TableCell>
                                        <TableCell># Seeds</TableCell>
                                        <TableCell>Market Price</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Cabbages</TableCell>
                                        <TableCell>45</TableCell>
                                        <TableCell>8765</TableCell>
                                        <TableCell>345,000</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Carrots</TableCell>
                                        <TableCell>45</TableCell>
                                        <TableCell>8765</TableCell>
                                        <TableCell>345,000</TableCell>
                                    </TableRow>                            
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={6}>
                    <TableContainer component={Paper}>
                            <Table className={classes.table} size="small" aria-label="Farm Manager - Crops">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Harvests In Storage</TableCell>
                                        <TableCell># Plants</TableCell>
                                        <TableCell>Market Price</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Cabbages</TableCell>
                                        <TableCell>45</TableCell>
                                        <TableCell>8765</TableCell>
                                        <TableCell>345,000</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Carrots</TableCell>
                                        <TableCell>45</TableCell>
                                        <TableCell>8765</TableCell>
                                        <TableCell>345,000</TableCell>
                                    </TableRow>                            
                                </TableBody>
                            </Table>
                        </TableContainer>                        
                    </Grid>
                </Grid>
                <Grid className={classes.grid} container>
                    <Grid item xs={8} justify="left">
                        <Heading color="white" heading={signedInUser.username + "'s Crops"} align={"left"}/>
                    </Grid>
                    <Grid item xs={2} align="right">
                        <FormControl variant="outlined" className={classes.formControl} style={{width:"50%",marginTop:"-8px"}}>
                            <InputLabel id="demo-simple-select-outlined-label">Filter</InputLabel>
                            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" onChange={handleChange} value={filter} style={{color:"white"}} label="Filter">
                                <MenuItem value="planted">Planted</MenuItem>
                                <MenuItem value="harvested">Harvested</MenuItem>
                                <MenuItem value="All">All</MenuItem>
                            </Select>
                        </FormControl>                      
                    </Grid>
                    <Grid item xs={2} align="right">
                        <Icon className={classes.linksAddBtn} style={{marginTop:"5px",cursor:"pointer"}} aria-label="Add Crop">add_circle</Icon>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="Farm Manager - Crops">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Planted</TableCell>
                                    <TableCell># Sub Plots</TableCell>
                                    <TableCell>Purchased Seeds</TableCell>
                                    <TableCell>Seed Price</TableCell>
                                    <TableCell>Planting Costs</TableCell>
                                    <TableCell>Sold For</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow style={{cursor:"pointer"}}>
                                    <TableCell>Cabbages</TableCell>
                                    <TableCell>93</TableCell>
                                    <TableCell>0</TableCell>
                                    <TableCell>0</TableCell>
                                    <TableCell>0</TableCell>
                                    <TableCell>346,000</TableCell>
                                    <TableCell><Icon className={classes.linksAddBtn} style={{marginTop:"5px",cursor:"pointer"}} aria-label="Harvest Crop">timelapse</Icon></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </div>
    );
}