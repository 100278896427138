import React, {createContext, useEffect, useState} from 'react';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import userProfile from '../actions/authentication/userProfile';
export const AuthenticationContext = createContext();

export const fbConfig = {
    apiKey: "AIzaSyAV62-g5FItpnmHHashWFMygvoGUkEgPo0 ",
    authDomain: "ao-market-tools.firebaseapp.com",
    databaseURL: "https://ao-market-tools.firebaseio.com",
    projectId: "ao-market-tools",
    storageBucket: "ao-market-tools.appspot.com",
    messagingSenderId: "1059010104432"
};

firebase.initializeApp(fbConfig);

export default (props) => {
    const auth = firebase.auth();
    const fbStore = firebase.firestore();
    const [isLoading, setIsLoading] = useState(true);
	const [signedInUser, setSignedInUser] = useState('');
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	useEffect(() => {
		let	authListener = auth.onAuthStateChanged(user => {
			if(user) {
				userProfile(user.uid, fbStore)
				.then(returnedUserProfile => {
					setSignedInUser(returnedUserProfile);
					setIsAuthenticated(true);
				})
				.catch(err => {
					setSignedInUser('');
					setIsAuthenticated(false);
				})
			} else {
				setSignedInUser('');
				setIsAuthenticated(false);
			}
			setIsLoading(false);
		})

		return () => {
			authListener && authListener();
		}
    }, [auth, fbStore])
    
    const signOut = () => {
		setIsAuthenticated(false);
		setSignedInUser('');
		auth.signOut();
    }
    
    const signIn = async (credentials) => {
        return auth.signInWithEmailAndPassword(credentials.email, credentials.password);
    }

    const signUp = (details) => {
        return auth.createUserWithEmailAndPassword(details.email, details.password);
    }

    return (
        <AuthenticationContext.Provider value={{ isLoading, signedInUser, isAuthenticated, signOut, signIn, signUp, fbStore }}>
            {props.children}
        </AuthenticationContext.Provider>
    )
}