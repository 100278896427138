import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { AuthenticationContext } from '../../context/AuthenticationContext';
import { DataAccessContext } from '../../context/DataAccessContext';
import Posts from '../blog/Posts';
import PostEditor from '../blog/PostEditor';
import { newPost } from '../../utilities/data';
import FullPostDialog from '../blog/FullPostDialog';
import Loading from '../common/Loading';


const useStyles = makeStyles(theme => ({
    addNewsBtn: theme.addnewsbtn,
    loadMoreBtn: theme.loadMoreBtn
}));

export default ({ channel }) => {
    const classes = useStyles();
    const {signedInUser} = useContext(AuthenticationContext);
    const {read, write, add, remove} = useContext(DataAccessContext);
    const [showPostEditor, setShowPostEditor] = useState(false);
    const [editPost, setEditPost] = useState(newPost);
    const [showPost, setShowPost] = useState(false);
    const [fullPost, setFullPost] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasMorePosts, setHasMorePosts] = useState(false);
    const [posts, setPosts] = useState([]);
    const [lastPost, setLastPost] = useState({});
    const [reload, setReload] = useState(false);
    const pageSize = 4;

    useEffect(() => {
        const getInitialPosts = async () => {
            let first = read('posts', {field: 'posted', direction:'desc'}, {field:'channel', operator:'==', value:channel}, null, pageSize);

            first.get().then(snapshot => {
                let results = snapshot.docs.map(doc => {
                    let dbDoc = doc.data();
                    dbDoc.id = doc.id;
                    return dbDoc;
                });
    
                if(snapshot.docs.length > 0) {
                    setPosts(results);
                    setLastPost(snapshot.docs[snapshot.docs.length - 1]);
                    
                    setHasMorePosts(true);
                    setReload(false)
                }
                setIsLoaded(true);
            })
        }
        getInitialPosts();
    },[read, channel, reload]);

    const loadNextPage = () => {
        let next = read('posts', {field: 'posted', direction:'desc'}, {field:'channel', operator:'==', value:channel}, lastPost.data().posted, pageSize);

        next.get().then(snapshot => {
            let results = snapshot.docs.map(doc => {
                let dbDoc = doc.data();
                dbDoc.id = doc.id;
                return dbDoc;
            });

                if(snapshot.docs.length > 0) {
                    setPosts(JSON.parse(JSON.stringify(posts.concat(results))));
                    setLastPost(snapshot.docs[snapshot.docs.length - 1]);
                    setHasMorePosts(true);
                } else {
                    setHasMorePosts(false);
                }
        });        
    }

    const openDialog = () => {
        setEditPost(newPost);
        setShowPostEditor(true);
    }
    
    const closeDialog = () => {
        setShowPostEditor(false);
    }
    
    const handleClose = () => {
        setShowPost(false);
    }
    
    const handlePost = (postID) => {
        let fp = posts.filter(post => post.id === postID);
        if(fp.length > 0) {
            setFullPost(fp[0]);
            setShowPost(true);
        }
    }
    
    const submitPost = async (data) => {
        closeDialog();
        let oldPost = posts.filter(post => post.id === data.id);
        if(oldPost.length > 0) {
            write('posts',data.id,data);
            setIsLoaded(false);
        } else {
            add('posts', data);
            setIsLoaded(false);
        }
        setReload(true);
    }
    
    const deletePost = (postID) => {
        closeDialog();
        setIsLoaded(false);
        remove('posts', postID);
        setReload(true);
    }
    
    const editNewsPost = (postID) => {
        let ep = posts.filter(post => post.id === postID)[0];
        setEditPost(ep);
        setShowPostEditor(true);
    }

    return (
        isLoaded?
            <>
                {signedInUser.uid && <div><button className={classes.addNewsBtn} onClick={() => {openDialog()}}>Add News Item</button></div>}
                {showPost && <FullPostDialog post={fullPost} channel={channel} handleClose={handleClose}/>}
                <Posts posts={posts} channel={channel} handleEdit={editNewsPost} handlePost={handlePost}/>
                {showPostEditor && <PostEditor post={editPost} channel={channel} handleClose={closeDialog} submitPost={submitPost} deletePost={deletePost}/>}
                {hasMorePosts && <div className={classes.loadMoreBtn} onClick={loadNextPage}><h3>Load More Posts...</h3></div>}
            </>
        :
            <Loading message="Fetching posts, be a second or two..."/>
    )
}