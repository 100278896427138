import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { formatPostDate } from '../../utilities';

import { AuthenticationContext } from '../../context/AuthenticationContext';

const useStyles = makeStyles(theme => ({
    newsItemBox: theme.newsItemBox,
    newsDraftItemBox: theme.newsDraftItemBox,
    newsItemInnerBox: theme.newsItemInnerBox,
    newsTitle: theme.newsTitle,
    newsSubtitle: theme.newsSubtitle,
    newsBody: theme.newsBody,
    newsBodyContentAnchor: theme.newsBodyContentAnchor,
    newsHR: theme.newsHR,
    editNewsBtn: theme.editnewsbtn
}));

export default ({posts, channel, handleEdit, handlePost}) => {
    const classes = useStyles();
    const {signedInUser} = useContext(AuthenticationContext);
    const role = 1; //Need to replace this with the logged in users roles once I decide other users can use the system.

    const anyPosts = posts !== undefined;

    const postedBy = (data) => {
        return <div className={classes.newsSubtitle}><b>Posted:</b> <i>{formatPostDate(data.posted)} by {data.username}</i></div>
    }

    const showDraftPost = (uid, role, post, channel) => {
        if(post.published) return true;
        if(post.uid === signedInUser.uid) return true;
        if(uid !== undefined && role < 10) return true;
        return false;
    }

    const previewPost = (data) => {
        const reviewLink = "<div class='newsAnchor' style='margin-top:10px'>Click here to continue...</div>";
        return (
            <>
                <div className={classes.newsBody} dangerouslySetInnerHTML={{__html: data["preview"]}}></div>
                {data.content && <div className={classes.newsBody} onClick={() => {handlePost(data.id)}} dangerouslySetInnerHTML={{__html: reviewLink}}></div>}                
            </>
        )
    }

    const post = (data, idx) => {
        return (
            showDraftPost(signedInUser.uid, role, data)?
                <div className={(data.published?classes.newsItemBox:classes.newsDraftItemBox)} key={"BLOG-"+channel+idx}>
                    <div className={classes.newsItemBox + " " + classes.newsItemInnerBox}>
                        <div className={classes.newsTitle}>
                            <h2>{data.title}</h2>
                            {signedInUser.uid?
                                <button className={classes.editNewsBtn} onClick={() => {handleEdit(data.id)}}>Edit Post</button>
                            :
                                <></>
                            }
                        </div>
                        {postedBy(data)}
                        <hr className={classes.newsHR} />
                        {data.preview?
                            previewPost(data)
                        :
                            <div className={classes.newsBody} dangerouslySetInnerHTML={{__html: data["content"]}}></div>
                        }
                    </div>
                </div>
            :
                <></>
        )
    }    

    return (
        anyPosts?
            posts.map((data, idx) => {
                if(data.channel === channel) {
                    return post(data, idx);
                }
                return null
            })
        :
            <></>
    )
}