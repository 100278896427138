import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle, DialogActions, Grid, TextField, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
    }
}));

export default ({ handleUpdate }) => {
    const classes = useStyles();
    const [plots, setPlots] = useState('');
    const [allowSubmit, setAllowSubmit] = useState(false);
    
    const handleClose = () => {

    }

    const handleChange = (e) => {
        let data = e.target.value;
        if(!isNaN(data)) {
            data > 0? setAllowSubmit(true):setAllowSubmit(false);
        }
        setPlots(data);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handleUpdate(plots)
    }

    return (
        <Dialog fullWidth={true} maxWidth="md" open={true} onClose={handleClose} disableEnforceFocus disableAutoFocus={true} aria-labelledby="farmSetup" style={{marginTop:"-20%"}}>
            <DialogTitle id="farmSetup" style={{textAlign:"center"}}>Farm Setup</DialogTitle>
            <DialogContent>
                <Grid container style={{textAlign:"center"}}>
                    <Grid item xs={12}>
                        <p>Please provide the following detials to be able to start using the farming tools</p>
                    </Grid>
                    <Grid item xs={12}>
                        <form autoComplete="off" onSubmit={handleSubmit}>
                            <TextField id="plots" value={plots} onChange={handleChange} label="Number of Farm Sub Plots" variant="outlined" helperText="A primary farm plot has 9 sub plots. Please add the total number of sub plots your farm has."/>
                        </form>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit} color="secondary" disabled={!allowSubmit}>Update Farm Details</Button>
            </DialogActions>
        </Dialog>
    )
}