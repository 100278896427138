import React, {userState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { DataAccessContext } from '../../../context/DataAccessContext';
import { Button, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    content: theme.subHome,
    grid: theme.grid,
    contentBGOpacity: theme.contentBGOpacity,
    btns: theme.btns
}));

export default () => {
    const classes = useStyles();
    const {read, write, add, remove} = useContext(DataAccessContext);

    return (
        <div className={classes.content + " " + classes.contentBGOpacity}>
            <Grid container>
                <Grid item xs={1}>
                    <Button className={classes.btns} component={Link} to="/farming">Home</Button>
                </Grid>
                <Grid item xs={1}>
                    <Button className={classes.btns} component={Link} to="/farming/crops">Crops</Button>
                </Grid>

            </Grid>
        </div>
        
    );
}